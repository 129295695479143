/* eslint-disable no-shadow */
export interface DataServicesError extends Error {
  Errors: string[];
}

class DataServicesErrorConstructor extends Error implements DataServicesError {
  private errors: string[];

  constructor(...errors: string[]) {
    super(errors[0] ?? 'The server returned an error');

    if (errors === null) {
      throw ReferenceError('Argument \'errors\' is null');
    }

    this.errors = errors;
    Object.setPrototypeOf(this, new.target.prototype);
  }

  get Errors(): string[] { return this.errors; }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DataServicesError = DataServicesErrorConstructor;
export default DataServicesError;

declare global {

  interface DataServicesError extends Error {
    readonly Errors: string[];
  }

  interface DataServicesErrorConstructor {
    new(...errors: string[]): DataServicesError;
    readonly prototype: DataServicesError;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const DataServicesError: DataServicesErrorConstructor;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const windowLocal: any = window;
windowLocal.DataServicesError = windowLocal.DataServicesError || DataServicesError;
