import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {
    vuetify,
} from './plugins';

import '@/assets/index.scss';
import '@/components';
import '@/directives';
import '@/globals';

const isProd = process.env.NODE_ENV === 'production';

Vue.config.devtools = !isProd || (/(true|1|enabled|on)/i).test(process.env.VUE_APP_DEV_TOOLS);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
