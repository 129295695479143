/* eslint-disable @typescript-eslint/no-explicit-any, no-shadow */

// https://dev.to/krumpet/generic-type-guard-in-typescript-258l#extending-the-type-guard-to-work-for-primitive-types

/*
 * FOR VUE FILES WHERE THIS EXTENSION IS NOT AVAILIBLE, USE THE FOLLOWING LINE
 * declare function is<T>(a: any, b: T): a is T;
 */
interface TypeMap { // for mapping from strings to types
  string: string;
  number: number;
  boolean: boolean;
  // eslint-disable-next-line
  function: Function;
}

type PrimitiveOrConstructor =
  | { new(...args: any[]): any }
  | keyof TypeMap;

// infer the guarded type from a specific case of PrimitiveOrConstructor
type GuardedType<T extends PrimitiveOrConstructor> =
  T extends { new(...args: any[]): infer U } ? U : T extends keyof TypeMap ? TypeMap[T] : never;

// finally, guard ALL the types!
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function is<T extends PrimitiveOrConstructor>(o: any, className: T): o is GuardedType<T> {
  const localPrimitiveOrConstructor: PrimitiveOrConstructor = className;
  if (typeof localPrimitiveOrConstructor === 'string') {
    return typeof o === localPrimitiveOrConstructor;
  }
  return o instanceof localPrimitiveOrConstructor;
}

export { is };
export default is;

declare global {
  function is<T extends PrimitiveOrConstructor>(o: any, className: T): o is GuardedType<T>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/naming-convention
const localWindow: any = window;
localWindow.is = localWindow.is || is;
