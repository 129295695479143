










import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    HeaderComponent: () => import('@/components/HeaderComponent.vue'),
  },
})
export default class HomeView extends Vue { }
