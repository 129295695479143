












import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Moto001: () => import('@/components/Moto001.vue'),
    CompellingImagery001: () => import('@/components/CompellingImagery001.vue'),
  },
})
export default class HomeView extends Vue { }
