import Vue from 'vue';
import Vuetify, { UserVuetifyPreset } from 'vuetify';
import opIcons from './opIcons';

//import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const config: UserVuetifyPreset = {
  icons: {
    iconfont: 'fa',
    values: {
      ...opIcons,
    },
  },
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    /*
      THIS IS WHERE WE OVERRIDE COLOR'S BY NAME LIKE SO...
      themes: {
        light: {
          primary: 'blue-dark-1',
          info: 'green-light-1'
        },
        dark: { ... }
      }
    */
  },
};

export const vuetify = new Vuetify(config);
export default vuetify;
