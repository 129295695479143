import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '/solutions',
    name: 'Solutions',
    component: () => import('../views/SolutionsView.vue'),
  },
  {
    path: '/about-us',
    name: 'About-Us',
    component: () => import('../views/AboutView.vue'),
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: () => import('../views/ContactUsView.vue'),
    props: (r) => ({
      form: r.params.form || 'quote',
    }),
  },
];

const router = new VueRouter({
  routes,
})

export default router;
