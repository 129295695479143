import Vue from 'vue';

let prefix = process.env.VUE_APP_TITLE;
if (!prefix) {
  // THIS IS NOT WORKING IN WHEN DEPLOYED
  // eslint-disable-next-line no-console
  console.debug(`Object.keys(process.env): ${Object.keys(process.env)}`);
  prefix = 'Welcome to Omegus Prime';
}

Vue.directive('title', {
  inserted: (_, binding) => { document.title = `${binding.value} - ${prefix}`; },
  update: (_, binding) => { document.title = `${binding.value} - ${prefix}`; },
});

export default null;
